<template>
  <div class="rtl">
    <v-card style="padding: 10px">
      <v-row>
        <v-col>
          <span class="panelTitle">مدیریت پیام های پشتیبانی</span>
        </v-col>
      </v-row>
      <hr style="margin: 20px">
      <v-row>
        <v-col style="max-width:fit-content;margin-top:10px;">
          <label>جستجو کاربر :</label>
        </v-col>
        <v-col style="max-width:fit-content;">
          <input class="Input" v-model="ticketSearchQuery" placeholder="شناسه کاربری">
        </v-col>
        <!-- <div class="col-2"></div> -->
        <div class="col-3">
          <button class="greenButton" @click="getTicketsFilterUsername()">جستجو </button>
        </div>
      </v-row>
      <div class="row" style="margin:30px 0">
        <div class="col-2" style="max-width:fit-content;margin-top:10px;padding-left:0;">از تاریخ</div>
        <div class="col-2" style="max-width:fit-content;margin-top:10px;padding-right:0;">:</div>
        <div class="col-3" style="max-width:fit-content;">
          <satrex-date-picker v-model="fromDateTime"></satrex-date-picker>
        </div>
        <div class="col-2" style="max-width:fit-content;margin-top:10px;padding-left:0;">تا تاریخ</div>
        <div class="col-2" style="max-width:fit-content;margin-top:10px;padding-right:0;">:</div>
        <div class="col-3" style="max-width:fit-content;">
          <satrex-date-picker v-model="toDateTime"></satrex-date-picker>
        </div>
        <div class="col-2" style="max-width:fit-content;">
          <button class="greenButton" @click="getTicketsFilterDate()">فیلتر تاریخ</button>
        </div>
      </div>
      <v-card style="margin-top: 10px">
        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-card>
                  <v-card-title>
                    پیام های پشتیبانی
                    <v-spacer></v-spacer>
                    <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="headers" :items="tickets.filter((item)=>item.department.id === 2)" :search="search">
                    <template v-slot:item.operations="{ item }">
                      <v-row style="display:flex;">
                        <router-link to="replyTicket">
                          <button class="btn-success" style="padding: 0 2px;border-radius: 0.3em;color: rgba(102, 102, 102, 0.89);border: solid rgba(102, 102, 102, 0.89) 1px" @click="$root.replyTicket = item.id"> جزییات
                          </button>
                        </router-link>
                        <button v-if="item.status != 'ClosedBySupportUser' && item.status != 'ClosedByEndUser'" class="btn-success" style="border-radius: 0.3em;padding: 0 2px;color:white;border: solid rgba(102, 102, 102, 0.89);background-color: red!important;border: red;" @click="ticketClose(item.id)">بستن
                        </button>
                      </v-row>
                    </template>
                    <template v-slot:item.priority="{ item }">
                      {{ item.priority == 'Normal' ? 'متوسط' : (item.priority == 'Low') ? 'پایین' : 'بالا' }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      {{ item.status == 'WaitingForSupportUserReply' ? 'در انتظار پاسخ ادمین' : (item.status == 'AnsweredBySupportUser' ? 'پاسخ داده شده توسط ادمین' : (item.status == 'ClosedByEndUser' ? 'بسته شده توسط کاربر' : (item.status == 'ClosedBySupportUser' ? 'بسته شده توسط پشتیبان' : 'بسته شده توسط سیستم'))) }}
                    </template>
                    <template v-slot:item.createdAtUtc="{ item }">
                      {{ getPersianDate(item.createdAtUtc) }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-card>
                  <v-card-title>
                    پیام های پشتیبانی
                    <v-spacer></v-spacer>
                    <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :headers="headers" :items="tickets.filter((item)=>item.department.id === 3)" :search="search">
                    <template v-slot:item.operations="{ item }">
                      <v-row>
                        <router-link to="replyTicket">
                          <button class="btn-success" style="border-radius: 0.3em;padding:0 2px;color:rgba(102, 102, 102, 0.89);border: solid rgba(102, 102, 102, 0.89) 1px" @click="$root.replyTicket = item.id"> جزییات
                          </button>
                        </router-link>
                        <button v-if="item.status != 'ClosedBySupportUser' && item.status != 'ClosedByEndUser'" class="btn-success" style="padding:0 2px;border-radius: 0.3em;color:white;;background-color: red!important;border: red;" @click="ticketClose(item.id)">بستن
                        </button>
                      </v-row>
                    </template>
                    <template v-slot:item.priority="{ item }">
                      {{ item.priority == 'Normal' ? 'متوسط' : (item.priority == 'Low') ? 'پایین' : 'بالا' }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      {{ item.status == 'WaitingForSupportUserReply' ? 'در انتظار پاسخ ادمین' : (item.status == 'AnsweredBySupportUser' ? 'پاسخ داده شده توسط ادمین' : (item.status == 'ClosedByEndUser' ? 'بسته شده توسط کاربر' : (item.status == 'ClosedBySupportUser' ? 'بسته شده توسط پشتیبان' : 'بسته شده توسط سیستم'))) }}
                    </template>
                    <template v-slot:item.createdAtUtc="{ item }">
                      {{ getPersianDate(item.createdAtUtc) }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card>
  </div>
</template>


<script>
import axiosApi from "@/axios";
import moment from "jalali-moment";
import convertDate from "@/plugins/convertDate";
import Vue from "vue";
import getPersianDateTime from "@/plugins/getPersianDate";

export default {
  name: "AdminMessages",
  data() {
    return {
      tickets: [],
      fromDateTime: this.getDate(Date.now()),
      toDateTime: this.getDate(Date.now()),
      ticketSearchQuery: '',
      ticketSearchShow: false,
      search: '',
      headers: [
        {
          text: 'تیکت کد ',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'تلفن', value: 'starter.username' },
        { text: 'نام', value: 'starter.firstName' },
        { text: 'نام خوانوادگی', value: 'starter.lastName' },
        { text: 'موضوع', value: 'title' },
        { text: 'اولویت', value: 'priority' },
        { text: 'زمان ارسال', value: 'createdAtUtc' },
        { text: 'وضعیت', value: 'status' },
        { text: 'عملیات', value: 'operations' },
      ],
      items: [
        'پشتیبانی فنی', 'پشتیبانی مالی',
      ],
      tab: null

    };
  },
  methods: {
    getTickets() {
      axiosApi().post('/api/Manage/v1/Ticket/GetList', {}).then(({ data }) => {
        this.tickets = data.data.reverse();
      });
    },
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    getTicketsFilterDate() {
      axiosApi().post('/api/Manage/v1/Ticket/GetList', {
        fromDateTime: convertDate(this.fromDateTime),
        toDateTime: convertDate(this.toDateTime)
      }).then(({ data }) => {
        this.tickets = data.data;
      });
    },
    getTicketsFilterUsername() {
      axiosApi().post('/api/Manage/v1/Ticket/GetList', {
        username: this.ticketSearchQuery
      }).then(({ data }) => {
        this.tickets = data.data;
      });
    },
    getDate(date) {
      return moment(date).locale('fa').format('jYYYY-jMM-jDD');
    },
    ticketClose(id) {
      axiosApi().post('/api/Manage/v1/Ticket/CloseTicket', {
        id: id
      }).then(({ data }) => {
        if (data['isSuccess']) {
          this.getTickets();
          {
            Vue.$toast.open({
              message: 'تیکت با موفقیت بسته شد',
              type: 'success',
              position: 'top-right'
            });
          }
        } else
          data.Errors.forEach((item) => {
            Vue.$toast.open({
              message: item.Description,
              type: 'error',
              position: 'top-right'
            });
          });
      });
    }
  },
  mounted() {
    this.getTickets();
  }
};
</script>

<style scoped>

</style>
